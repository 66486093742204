.darkTheme {
  background-color: #1a202c;
  border: 1px solid #3f444e;
  padding: 5px;
  width: 335px;
}

.lightTheme {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  padding: 5px;
  width: 335px;
}